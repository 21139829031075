
import {getCookie, setCookie, checkCookieAndSet} from "../cookieFunctions";

document.addEventListener('DOMContentLoaded', function () {

  //RESET BUTTON
 var resetButton = document.getElementById("resetButton");

  if(resetButton !== null){
    resetButton.onclick = function() {
      setCookie('increaseSize', '0', 1);
      setCookie('easyRead', '1', 1);
      setCookie('fontChange', '2', 1);
      location.reload();
    }
   }
});
