
import {getCookie, setCookie, checkCookieAndSet} from "../cookieFunctions";

document.addEventListener('DOMContentLoaded', function () {

  //RESET BUTTON

 var fontChangeButton = document.getElementById("fontChangeButton");
 var text = document.getElementsByTagName('*');
 var pTag = document.getElementsByTagName('p');
 var liTag = document.getElementsByTagName('li');
 var spanTag = document.getElementsByTagName('span');
 var labelTag = document.getElementsByTagName('label');
 var buttonTag = document.getElementsByTagName('button');
 var aTag = document.getElementsByTagName('a');
 var h1Tag = document.getElementsByTagName('h1');
 var h2Tag = document.getElementsByTagName('h2');
 var h3Tag = document.getElementsByTagName('h3');
 var h4Tag = document.getElementsByTagName('h4');
 var h5Tag = document.getElementsByTagName('h5');
 var h6Tag = document.getElementsByTagName('h6');

 var fontChangeCookie = checkCookieAndSet('fontChange', '0', 1);

checkCookie(getCookie('fontChange'));

function runCookieChecker(myCookie){

  var fontChecked = fontChangeButton.querySelector('.checked');

  if (myCookie == 1) {
    fontChecked.style.setProperty ('display', 'none', 'important');
  }else{
    fontChecked.style.setProperty ('display', 'block', 'important'); 
  }

  switch(myCookie) {
    case '0':
      // code block
      setCookie('fontChange', '1', 1);
      checkCookie(getCookie('fontChange'));
      break;
    case '1':
        // code block defaultFont
          setCookie('fontChange', '2', 1);
          checkCookie(getCookie('fontChange'));
          break;
    case '2':
      // code block
        setCookie('fontChange', '0', 1);
        checkCookie(getCookie('fontChange'));
        break;
    default:
        // code block
        setCookie('fontChange', '0', 1);
        checkCookie(getCookie('fontChange'));
  }

}

  if(fontChangeButton !== null){
    fontChangeButton.onclick = function() {
      runCookieChecker(getCookie('fontChange'));
    }
   }


    function zeroCookie(textEl){
      console.log('NonBreakingSpaceOverride');

      //textEl.style.removeProperty ('font-family', "'helvetica'");
      textEl.style.setProperty ('font-family', "'OpenDyslexic-Regular'");
    }

    function oneCookie(textEl){
      console.log('helvetica');
       //textEl.style.removeProperty ('font-family', "'NonBreakingSpaceOverride'");
        textEl.style.setProperty ('font-family', "'Atkinson-Hyperlegible-Regular'");
        
      
    }

    function twoCookie(textEl){
      console.log('Default');
      textEl.style.removeProperty ('font-family', "'helvetica'");
      textEl.style.removeProperty ('font-family', "'NonBreakingSpaceOverride'");
    }

    function checkCookie(myCookie){
      if(myCookie == 2){
          for (var i = 0; i < text.length; i++) {
            var textEl = text[i];
            twoCookie(textEl);
          }    
      }

      if(myCookie == 0){
        for (var i = 0; i < text.length; i++) {
          var textEl = text[i];
          zeroCookie(textEl);
        }    
      }

    if(myCookie == 1){
      for (var i = 0; i < text.length; i++) {
        var textEl = text[i];
        oneCookie(textEl);
      }    
    }
    }

}, false);
