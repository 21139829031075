

import {getCookie, setCookie, checkCookieAndSet} from "../cookieFunctions";

document.addEventListener('DOMContentLoaded', function () {

function googleTranslateElementInit2(newLang) {
  console.log(newLang);
  new google.translate.TranslateElement({pageLanguage: newLang}, 'google_translate_element');
}

 let translateTextButton = document.getElementById("gtranslate_selector");
 var text = document.getElementsByTagName('*');

  checkCookieAndSet('translateText', 'en', 1);

  //setCookie('increaseSize', '0', 1);
  checkCookie(getCookie('translateText'));

  function runCookieChecker(translateText){

    if (translateText == null || translateText == 0) {
      setCookie('translateText', '1', 1);
      checkCookie(getCookie('translateText'));
    } 
      if (translateText <= 10) {
      var newSize = parseFloat(translateText) + 1;
      setCookie('translateText', newSize, 1);
      checkCookie(getCookie('translateText'));

    }
    if (translateText > 10) {
      setCookie('translateText', '0', 1);
      checkCookie(getCookie('increatranslateTextseSize'));

    }
  }



  if(translateTextButton !== null){
    translateTextButton.onchange = function() {
      console.log(translateTextButton.value)
      doGTranslate(translateTextButton.value);
      //runCookieChecker(getCookie('translateText'));
    }}

    function checkCookie(){

        function increateTextSize(text, translateText) {
         //console.log(textIncrease);
  

  
           if(translateText <= 10){
          for (var i = 0; i < text.length; i++) {
              console.log(translateText);

              var style = window.getComputedStyle(text[i], null).getPropertyValue('font-size');
              var fontSize = parseFloat(style);   
              var divide = translateText / 5;
              var fontNewSize = (fontSize + divide) + 'px';  
         
              //Set new style
              text[i].style.setProperty ('font-size', fontNewSize, 'important');
              //text[i].style.removeProperty ('font-size', '50px', 'important');
          }
        }
        if(translateText > 10){
          for (var i = 0; i < text.length; i++) {
              console.log(textIncrease);

              var style = window.getComputedStyle(text[i], null).getPropertyValue('font-size');
              var fontSize = parseFloat(style);     
              var fontNewSize = (fontSize + 0) + 'px';  
 
              //Set new style
              text[i].style.removeProperty ('font-size', fontNewSize, 'important');
              //text[i].style.removeProperty ('font-size', '50px', 'important');
          }
        } 
       }
       increateTextSize(text, getCookie('translateText'));
    }


if(!window.gt_translate_script){
  window.gt_translate_script=document.createElement('script');
  gt_translate_script.src='https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2';
  document.body.appendChild(gt_translate_script);
}

function GTranslateGetCurrentLang() {
  var keyValue = document['cookie'].match('(^|;) ?googtrans=([^;]*)(;|$)');return keyValue ? keyValue[2].split('/')[2] : null;
}

function GTranslateFireEvent(element,event){try{if(document.createEventObject){var evt=document.createEventObject();element.fireEvent('on'+event,evt)}else{var evt=document.createEvent('HTMLEvents');evt.initEvent(event,true,true);element.dispatchEvent(evt)}}catch(e){}}

function doGTranslate(lang_pair){
  if(lang_pair.value)lang_pair=lang_pair.value;
  if(lang_pair=='')
  return;
  
  var lang=lang_pair.split('|')[1];
  
  if(GTranslateGetCurrentLang() == null && lang == lang_pair.split('|')[0])
  return;

  if(typeof ga=='function'){
    ga('send', 'event', 'GTranslate', lang, location.hostname+location.pathname+location.search);}var teCombo;var sel=document.getElementsByTagName('select');
    for(var i=0;i<sel.length;i++)if(sel[i].className.indexOf('goog-te-combo')!=-1){
      teCombo=sel[i];
      break;
    }
    
    if(document.getElementById('google_translate_element2')==null||document.getElementById('google_translate_element2').innerHTML.length==0||teCombo.length==0||teCombo.innerHTML.length==0){
      setTimeout(function(){
        doGTranslate(lang_pair)},500)
    }else{
      teCombo.value=lang; 
      GTranslateFireEvent(teCombo,'change');GTranslateFireEvent(teCombo,'change')
    }
  }


function googleTranslateElementInit() {
  new google.translate.TranslateElement({pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.SIMPLE}, 'google_translate_element');
}
});
