//const jqueryUi = require("./modules/jquery-ui.min.js");
// const bootstrap = require("bootstrap");
 //import "./modules/cookieFunctions";
// import "./modules/scripts.js";
// import "bootstrap/js/dist/modal";
// import "./modules/nav";
// import "bootstrap/js/dist/collapse";
// import "bootstrap/js/dist/alert";
// import "bootstrap/js/dist/button";
//import "bootstrap/js/dist/carousel";
// import "bootstrap/js/dist/dropdown";
// import "bootstrap/js/dist/popover";
// import "bootstrap/js/dist/scrollspy";
// import "bootstrap/js/dist/tab";
// import "bootstrap/js/dist/toast";
// import "bootstrap/js/dist/tooltip";
//import "./modules/wbn-slider";
import "./modules/colorChange";
import "./modules/increaseText";
import "./modules/decreaseText";
import "./modules/translateText";
import "./modules/fontChange";
import "./modules/resetAll";