 import {getCookie, setCookie, checkCookieAndSet} from "../cookieFunctions";

 document.addEventListener('DOMContentLoaded', function () {

  let resetButton = document.getElementById("resetButton");
  let increaseTextButton = document.getElementById("increaseTextButton");
  let decreaseTextButton = document.getElementById("decreaseTextButton");
  var pTag = document.getElementsByTagName('p');
  var liTag = document.getElementsByTagName('li');
  var spanTag = document.getElementsByTagName('span');
  var labelTag = document.getElementsByTagName('label');
  var buttonTag = document.getElementsByTagName('button');
  var aTag = document.getElementsByTagName('a');
  var h1Tag = document.getElementsByTagName('h1');
  var h2Tag = document.getElementsByTagName('h2');
  var h3Tag = document.getElementsByTagName('h3');
  var h4Tag = document.getElementsByTagName('h4');
  var h5Tag = document.getElementsByTagName('h5');
  var h6Tag = document.getElementsByTagName('h6');

  var body = document.getElementsByTagName('body');

  checkCookie(getCookie('increaseSize'));

  function runCookieChecker(increaseSize){
  console.log(getCookie('increaseSize'))


    if (increaseSize == undefined) {
      setCookie('increaseSize', '0', 1);
      checkCookie(getCookie('increaseSize'));
    } if (increaseSize < 5 || increaseSize  < 0) {
      var newSize = parseFloat(increaseSize) + 1;
      setCookie('increaseSize', newSize, 1);
      checkCookie(getCookie('increaseSize'));
    }
    
  }

  if(increaseTextButton !== null){
    increaseTextButton.onclick = function() {
      runCookieChecker(getCookie('increaseSize'));
    }}


    /*body Text*/
    function bodyText(textIncreaseParse, textElement){
      
            let fontSize;
            switch(textIncreaseParse) {
              case 1:
                // code block
                fontSize = 1.1
                break;
              case 2:
                // code block
                fontSize = 1.2
                break;
              case 3:
                // code block
                fontSize = 1.4
                break;
              case 4:
                // code block  
                fontSize = 1.6
                break;
              case 5:
                  // code block
                fontSize = 1.8
                  break;
              default:
                fontSize = 1;
            }
            let resizedFont = fontSize + 'em';
            textElement.style.setProperty ('font-size', resizedFont, 'important');
            textElement.style.setProperty ('line-height', 'initial', 'important');
    }

          /*Title Text*/
          function h1Text(textIncreaseParse, textElement){
            
            let fontSize;
            switch(textIncreaseParse) {
              case 1:
                // code block
                fontSize = 3
                break;
              case 2:
                // code block
                fontSize = 3.5
                break;
              case 3:
                // code block
                fontSize = 4
                break;
              case 4:
                // code block  
                fontSize = 4.5
                break;
              case 5:
                  // code block
                fontSize = 5
                  break;
              default:
                fontSize = 1;
            }
            let resizedFont = fontSize + 'em';
            textElement.style.setProperty ('font-size', resizedFont, 'important');
            textElement.style.setProperty ('line-height', 'initial', 'important');
    }

              /*Title Text*/
     function h2Text(textIncreaseParse, textElement){
            
                let fontSize;
                switch(textIncreaseParse) {
                  case 1:
                    // code block
                    fontSize = 2.8
                    break;
                  case 2:
                    // code block
                    fontSize = 3.2
                    break;
                  case 3:
                    // code block
                    fontSize = 3.8
                    break;
                  case 4:
                    // code block  
                    fontSize = 4.2
                    break;
                  case 5:
                      // code block
                    fontSize = 4.8
                      break;
                  default:
                    fontSize = 1;
                }
                let resizedFont = fontSize + 'em';
                textElement.style.setProperty ('font-size', resizedFont, 'important');
                textElement.style.setProperty ('line-height', 'normal', 'important');
        }

                /*Title Text*/
        function h3Text(textIncreaseParse, textElement){
            
          let fontSize;
          switch(textIncreaseParse) {
            case 1:
              // code block
              fontSize = 2.5
              break;
            case 2:
              // code block
              fontSize = 3
              break;
            case 3:
              // code block
              fontSize = 3.2
              break;
            case 4:
              // code block  
              fontSize = 3.8
              break;
            case 5:
                // code block
              fontSize = 4
                break;
            default:
              fontSize = 1;
          }
          let resizedFont = fontSize + 'em';
          textElement.style.setProperty ('font-size', resizedFont, 'important');
          textElement.style.setProperty ('line-height', 'normal', 'important');
          }

                /*Title Text*/
           function h4Text(textIncreaseParse, textElement){
            
              let fontSize;
                  switch(textIncreaseParse) {
                    case 1:
                      // code block
                      fontSize = 2.2
                      break;
                    case 2:
                      // code block
                      fontSize = 2.8
                      break;
                    case 3:
                      // code block
                      fontSize = 3
                      break;
                    case 4:
                      // code block  
                      fontSize = 3.2
                      break;
                    case 5:
                        // code block
                      fontSize = 3.5
                        break;
                    default:
                      fontSize = 1;
                  }
                  let resizedFont = fontSize + 'em';
                  textElement.style.setProperty ('font-size', resizedFont, 'important');
                  textElement.style.setProperty ('line-height', 'normal', 'important');
                  }


          function h5Text(textIncreaseParse, textElement){
            
            let fontSize;
              switch(textIncreaseParse) {
                case 1:
                  // code block
                  fontSize = 2
                  break;
                case 2:
                  // code block
                  fontSize = 2.2
                  break;
                case 3:
                  // code block
                  fontSize = 2.5
                  break;
                case 4:
                  // code block  
                  fontSize = 2.8
                  break;
                case 5:
                    // code block
                  fontSize = 3
                    break;
                default:
                  fontSize = 1;
              }
              let resizedFont = fontSize + 'em';
              textElement.style.setProperty ('font-size', resizedFont, 'important');
              textElement.style.setProperty ('line-height', 'normal', 'important');
                        }

    function checkCookie(){

      var increaseChecked = increaseTextButton.querySelector('.checked');
      var decreaseChecked = decreaseTextButton.querySelector('.checked');

      if ( getCookie('increaseSize') > 0) {
        increaseChecked.style.setProperty ('display', 'block', 'important'); 
        decreaseChecked.style.setProperty ('display', 'block', 'important');
    
      }

        function increateTextSize(pTag, h1Tag, h2Tag, h3Tag, h4Tag, h5Tag, h6Tag, spanTag, aTag, buttonTag, labelTag, textIncrease) {

          var textIncreaseParse = parseFloat(textIncrease);

           if(textIncrease > 0){
              for (var i = 0; i < pTag.length; i++) {
                var textEl = pTag[i];
                var resizedFont = bodyText(textIncreaseParse, textEl);

                
              }

              for (var i = 0; i < liTag.length; i++) {
                var textEl = liTag[i];
                var resizedFont = bodyText(textIncreaseParse, textEl);
              }

              for (var i = 0; i < buttonTag.length; i++) {
                var textEl = buttonTag[i];
                var resizedFont = bodyText(textIncreaseParse, textEl);
              }

              for (var i = 0; i < labelTag.length; i++) {
                var textEl = labelTag[i];
                bodyText(textIncreaseParse, textEl);
                
              }

              /*
              for (var i = 0; i < aTag.length; i++) {
                var resizedFont = bodyText(textIncreaseParse);
                aTag[i].style.fontSize = resizedFont ;
              }
               */

              for (var i = 0; i < spanTag.length; i++) {
                var textEl = spanTag[i];
                bodyText(textIncreaseParse, textEl);
              }
             
              
              for (var i = 0; i < h1Tag.length; i++) {
                var textEl = h1Tag[i];
                h1Text(textIncreaseParse, textEl);
              }

              for (var i = 0; i < h2Tag.length; i++) {
                var textEl = h2Tag[i];
                h2Text(textIncreaseParse, textEl);
              }

              for (var i = 0; i < h3Tag.length; i++) {
                var textEl = h3Tag[i];
                h3Text(textIncreaseParse, textEl);
              }

              for (var i = 0; i < h4Tag.length; i++) {
                var textEl = h4Tag[i];
                h4Text(textIncreaseParse, textEl);
              }

              for (var i = 0; i < h5Tag.length; i++) {
                var textEl = h5Tag[i];
               h5Text(textIncreaseParse, textEl);
              }

              for (var i = 0; i < h6Tag.length; i++) {
                var textEl = h6Tag[i];
                h6Text(textIncreaseParse, textEl);
              }

      
           }

       }
       increateTextSize(pTag, h1Tag, h2Tag, h3Tag, h4Tag, h5Tag, h6Tag, spanTag, aTag, buttonTag, labelTag, getCookie('increaseSize'));
       
    }
 }, false);